import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function UpdateCommentsDialog(props) {

  let {classes,updateformData,updatecommentsCreateModal,updatehandleClose,updatehandleChangeInput,updatehandleSubmit}=props;
  
  return (
    <Dialog fullScreen open={updatecommentsCreateModal} onClose={updatehandleClose} >
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={updatehandleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Update Comments
      </Typography>
        <Button color="inherit" onClick={updatehandleClose}>
          Cancel
      </Button>
      </Toolbar>
    </AppBar>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Update Comments
        </Typography>
       
        <ValidatorForm
          onSubmit={updatehandleSubmit}
          >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
             
              <TextValidator
                label="firstName*"
                fullWidth
                onChange={updatehandleChangeInput}
                name="firstName"
                id="firstName"
                value={updateformData.firstName}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="lastName*"
                id="lastName"
                fullWidth
                onChange={updatehandleChangeInput}
                name="lastName"
                value={updateformData.lastName}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="email*"
                id="email"
                fullWidth
                onChange={updatehandleChangeInput}
                name="email"
                value={updateformData.email}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <TextValidator
                label="password*"
                id="password"
                fullWidth
                onChange={updatehandleChangeInput}
                name="password"
                value={updateformData.password}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="gender*"
                id="gender"
                fullWidth
                onChange={updatehandleChangeInput}
                name="gender"
                value={updateformData.gender}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="dob*"
                id="dob"
                fullWidth
                onChange={updatehandleChangeInput}
                name="dob"
                value={updateformData.dob}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="doj*"
                id="doj"
                fullWidth
                onChange={updatehandleChangeInput}
                name="doj"
                value={updateformData.doj}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="mobile*"
                id="mobile"
                fullWidth
                onChange={updatehandleChangeInput}
                name="mobile"
                value={updateformData.mobile}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="address*"
                id="address"
                fullWidth
                onChange={updatehandleChangeInput}
                name="address"
                value={updateformData.address}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="height*"
                id="height"
                fullWidth
                onChange={updatehandleChangeInput}
                name="height"
                value={updateformData.height}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="weight*"
                id="weight"
                fullWidth
                onChange={updatehandleChangeInput}
                name="weight"
                value={updateformData.weight}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
              type="submit"
            className={classes.submit}
          >
            Save
          </Button>
        </ValidatorForm>
      </div>
    </Container>
  </Dialog>
  );
}
