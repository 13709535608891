import { newsConstants } from '../_constants';
import { newsService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
export const newsActions = {
    login,
    logout,
    addNews,
    uploadImage,
    disableNews,
    deleteNews,
    updateNews,
    getAllNews,
    getNewsList,
    blankImage
};

function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        newsService.login(data)
            .then(
                news => {
                    dispatch(success(news));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(news) { return { type: newsConstants.LOGIN_REQUEST, news } }
    function success(news) { return { type: newsConstants.LOGIN_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.LOGIN_FAILURE, error } }
}
function logout() {
    newsService.logout();
    return { type: newsConstants.LOGOUT };
}

function getNewsList(data) {
    return dispatch => {
        dispatch(request());
        newsService.getNewsList(data)
            .then(
                news => dispatch(success(news)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GETALL_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.GETALL_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.GETALL_NEWS_FAILURE, error } }
}

function getAllNews(data) {
    return dispatch => {
        dispatch(request());
        newsService.getAllNews(data)
            .then(
                news => {
                    //console.log("news  ",news);
                    let returnResppnse=
                    news.getAllNews?news.getAllNews.map(news => ({ value: news.id, label: news.firstName+" "+news.lastName })):[];
                    let newRespnse={...news,getAllNews:returnResppnse}
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_ALL_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.GET_ALL_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.GET_ALL_NEWS_FAILURE, error } }
}

function addNews(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        newsService.addNews(data)
            .then(
                news => {
                    dispatch(success(news));
                    dispatch(this.getNewsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_RESTAURANT_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.ADD_RESTAURANT_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.ADD_RESTAURANT_NEWS_FAILURE, error } }
}

function updateNews(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        newsService.updateNews(data)
            .then(
                news => {
                    dispatch(success(news));
                    dispatch(this.getNewsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_RESTAURANT_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.ADD_RESTAURANT_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.ADD_RESTAURANT_NEWS_FAILURE, error } }
}

function disableNews(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        newsService.disableNews(maintempdata)
            .then(
                news => {
                    dispatch(success(news));
                    dispatch(this.getNewsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_RESTAURANT_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.ADD_RESTAURANT_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.ADD_RESTAURANT_NEWS_FAILURE, error } }
}

function deleteNews(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        newsService.deleteNews(maintempdata)
            .then(
                news => {
                    dispatch(success(news));
                    dispatch(this.getNewsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_RESTAURANT_NEWS_REQUEST } }
    function success(news) { return { type: newsConstants.ADD_RESTAURANT_NEWS_SUCCESS, news } }
    function failure(error) { return { type: newsConstants.ADD_RESTAURANT_NEWS_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        newsService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: newsConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: newsConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}


function blankImage() {
    return dispatch => dispatch(success());
    function success() { return { type: newsConstants.FILE_UPLOAD_BLANK_SUCCESS } }
}