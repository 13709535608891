import {
  newsConstants
} from '../_constants';

export function news(state = {}, action) {

  switch (action.type) {


    case newsConstants.GETALL_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GETALL_NEWS_SUCCESS:
      return {
        ...state,
        addNewsSuccess: false,
        items: action.news.getNewsList.list,
        total: action.news.getNewsList.total
      };
    case newsConstants.GETALL_NEWS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.GET_ALL_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_ALL_NEWS_SUCCESS:
      return {
        ...state,
        getAllNews: action.news.getAllNews
      };
    case newsConstants.GET_ALL_NEWS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.GETALL_NEWS_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GETALL_NEWS_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.news.listOfNotification.list,
        listOfNotificationtotal: action.news.listOfNotification.total
      };
    case newsConstants.GETALL_NEWS_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.UPDATE_NEWS_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.UPDATE_NEWS_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case newsConstants.UPDATE_NEWS_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.news.restaurantDetails
      };
    case newsConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case newsConstants.ADD_RESTAURANT_NEWS_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_RESTAURANT_NEWS_SUCCESS:
      return {
        ...state,
        addNewsSuccess: true
      };
    case newsConstants.ADD_RESTAURANT_NEWS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case newsConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case newsConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case newsConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case newsConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case newsConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case newsConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case newsConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.FILE_UPLOAD_BLANK_SUCCESS:
      return {
        ...state, filesDetails: {},
      };

    case newsConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case newsConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case newsConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.news.statics
      };
    case newsConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}