import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import AddNewsDialog from "../NewsManagement/components/AddNewsDialog/AddNewsDialog";
import ViewImageDialog from "../NewsManagement/components/ViewImageDialog/ViewImageDialog";
import UpdateNewsDialog from "../NewsManagement/components/UpdateNewsDialog/UpdateNewsDialog";

import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { newsActions } from '../../_actions';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';
const theme = createMuiTheme();

class NewsManagement extends Component {

  constructor(props) {
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.imageOpenCreateModal = this.imageOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal = this.updatehandleOpenCreateModal.bind(this);
    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: '',
      newsCreateModal: false,
      updatenewsCreateModal: false,
      viewnewsCreateModal: false,
      submitted: false,
      keyWord: '',
      formData: {
        "name": "",
        "address": "",
        "lat": "",
        "desc": "",
        "long": "",
        "mobile": "",
        "rating": "",
        "logo": "",
        "openTime": "",
        "closeTime": "",
        "countryCode": "",
        "storyLink": "",
        "type": "",
        "title": "",

      },
      updateformData: {
        "name": "",
        "address": "",
        "lat": "",
        "desc": "",
        "long": "",
        "mobile": "",
        "rating": "",
        "logo": "",
        "openTime": "",
        "closeTime": "",
        "countryCode": "",
        "storyLink": "",
        "type": "",
        "title": "",
      },
      viewformData: {
        "name": "",
        "address": "",
        "lat": "",
        "desc": "",
        "long": "",
        "mobile": "",
        "rating": "",
        "logo": "",
        "openTime": "",
        "closeTime": "",
        "countryCode": "",
        "storyLink": "",
        "type": "",
        "title": "",
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNewsList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addNewsSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "address": "",
          "lat": "",
          "desc": "",
          "long": "",
          "mobile": "",
          "rating": "",
          "logo": "",
          "openTime": "",
          "closeTime": "",
          "countryCode": "",
          "storyLink": "",
          "type": "",
          "title": "",
        },
        newsCreateModal: false,
        updatenewsCreateModal: false,
        viewnewsCreateModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  // Handle Click pagination change
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNewsList(data));
  }

  // Handle upload image
  handleFileLogo = (event) => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    console.log("eventeventeventevent", event.target.files[0]);
    this.setState({ selectedFileLogo: event.target.files[0] });
    // this.setState({ selectedFileLogo: filesDetails.image });

    // const { updateformData } = this.state;
    // updateformData['image'] = filesDetails.uploadedImageName;
    // this.setState({ updateformData });

  };


  handleUploadLogo = () => {

    if (this.state.selectedFileLogo) {
      this.props.dispatch(newsActions.uploadImage(this.state.selectedFileLogo));
      this.setState({ selectedFileLogo: null });
    } else {
      console.log("No File To Upload!");
    }
  };

  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getAllNews(data));
  }

  handleOpenCreateModal() {
    this.setState({ newsCreateModal: true });
  }
  imageOpenCreateModal(data) {
    this.setState({ viewnewsCreateModal: true, viewformData: data });
  }

  viewhandleClose=(data)=> {
    this.setState({ viewnewsCreateModal: false, viewformData: {} });
  }

  updatehandleOpenCreateModal(data) {
    this.setState({ updatenewsCreateModal: true, updateformData: data });
  }

  handleClose() {
    this.blankImage();
    this.setState({ newsCreateModal: false });
  }


  updatehandleClose() {
    this.blankImage();
    this.setState({ updatenewsCreateModal: false });

  }

  blankImage = () => {
    this.props.dispatch(newsActions.blankImage());
  }

  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  }

  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }



  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }


  handleSubmit = () => {
    let { news } = this.props;
    let { filesDetails } = news;

    let reqData = {
      "title": this.state.formData.title,
      "subtitle": this.state.formData.subtitle,
      "content": this.state.formData.content,
      "category": this.state.formData.category,
      "image": filesDetails && filesDetails.uploadedImageName ? filesDetails.uploadedImageName : "",
      "time": this.state.formData.time,
      "author": this.state.formData.author,
      "storyLink": this.state.formData.storyLink,
      "estimates": this.state.formData.estimates,
    }
    this.props.dispatch(newsActions.addNews(reqData));
    this.blankImage();

  }
  updatehandleSubmit = () => {

    let { news } = this.props;
    let { filesDetails } = news;

    let reqData = {
      "id": this.state.updateformData.id,
      "title": this.state.updateformData.title,
      "subtitle": this.state.updateformData.subtitle,
      "content": this.state.updateformData.content,
      "category": this.state.updateformData.category,
      "image": filesDetails && filesDetails.uploadedImageName ? filesDetails.uploadedImageName : "",
      "time": this.state.updateformData.time,
      "author": this.state.updateformData.author,
      "storyLink": this.state.updateformData.storyLink,
      "estimates": this.state.updateformData.estimates,

    }
    console.log("reqData  ", reqData);

    this.props.dispatch(newsActions.updateNews(reqData));
    // this.blankImage();

  }
  onChangeFile(event) {
    this.props.dispatch(newsActions.uploadImage(event.target.files[event.target.files.length - 1]));
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`)
  }

  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNews(tempdata))

        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });






    // console.log("asdf :: ", tempdata);
    // this.props.dispatch(newsActions.deleteNews(tempdata));
  }
  onDisable(data) {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    //console.log("asdf :: ",tempdata);
    this.props.dispatch(newsActions.disableNews(tempdata));
  }
  render() {

    let { news, classes } = this.props;
    let { items, total, filesDetails } = news;

    console.log("filesDetailsfilesDetailsfilesDetails", filesDetails);
    console.log("itemsitemsitemsitemsitemsitemsitems", items);


    // const { formData, updateformData, submitted } = this.state;

    // let receivedImageLogo = null;
    if (news.filesDetailsLogo) {
      console.log("filesDetails: ", news.filesDetailsLogo);
      // receivedImageLogo = news.filesDetailsLogo;
    }

    console.log("kkkkkkkkkkkkkk", items);

    return (
      <>
        <PageTitle title="News" />
        <Grid container >
          <Grid item xs={12} md={12} xs={12}>
            <Widget >
              <Grid container >
                <Grid item md={9} sm={4} />
                <Grid item md={2} sm={4} xs={6}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>

                <Grid item md={1} sm={4} xs={6}>
                  <Button variant="contained" color="primary" onClick={() => this.handleOpenCreateModal()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      updatehandleOpenCreateModal={this.updatehandleOpenCreateModal}
                      imageOpenCreateModal={this.imageOpenCreateModal}
                      onDeleteClick={this.onDeleteClick}
                      onDisable={this.onDisable} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <AddNewsDialog
          newsCreateModal={this.state.newsCreateModal}
          classes={classes}
          formData={this.state.formData}
          filesDetails={filesDetails}
          // handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleFileLogo={this.handleFileLogo}
          handleUploadLogo={this.handleUploadLogo}
        />
        <ViewImageDialog
          updatenewsCreateModal={this.state.viewnewsCreateModal}
          classes={classes}
          filesDetails={filesDetails}
          viewformData={this.state.viewformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          viewhandleClose={this.viewhandleClose}
          handleFileLogo={this.handleFileLogo}
          handleUploadLogo={this.handleUploadLogo}
        />
        <UpdateNewsDialog
          updatenewsCreateModal={this.state.updatenewsCreateModal}
          classes={classes}
          filesDetails={filesDetails}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
          handleFileLogo={this.handleFileLogo}
          handleUploadLogo={this.handleUploadLogo}
        />
      </>
    );

  }
}
function mapStateToProps(state) {
  const { news, users } = state;
  console.log("newsnewsnewsnewsnewsnews", news);

  return {
    news,
    users
  };
}

export default connect(mapStateToProps)(withStyles(styles)(withRouter(NewsManagement)));
