import { settingsConstants } from '../_constants';
import { settingsService } from '../_services';
import { alertActions } from '.';
export const settingsActions = {
    updateWebSettings,
    handleChangeInput,
    getWebSettings,

    updateSmsSettings,
    handleSmsChangeInput,
    getSmsSettings
};
function getWebSettings() {

    return dispatch => {
        dispatch(request());
        settingsService.getWebSettings()
            .then(
                settings => dispatch(success(settings)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: settingsConstants.GETALL_WEB_SETTINGS_REQUEST } }
    function success(settings) { return { type: settingsConstants.GETALL_WEB_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: settingsConstants.GETALL_WEB_SETTINGS_FAILURE, error } }

}
function handleChangeInput(getWebMgmt,name,value) {
    return dispatch => {
                    let settings={...getWebMgmt,[name]:value};
                    console.log("settings   ",settings);
                    dispatch(success(settings));
    };
    function success(settings) { return { type: settingsConstants.INPUTCHANGE_WEB_SETTINGS_SUCCESS, settings } }
}
function updateWebSettings(data) {
  
    return dispatch => {
        dispatch(request());
        settingsService.updateWebSettings(data)
            .then(
                settings => {
                    dispatch(success(settings));
                    dispatch(this.getWebSettings());
                    dispatch(alertActions.error("Web setting updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: settingsConstants.UPDATE_WEB_SETTINGS_REQUEST } }
    function success(settings) { return { type: settingsConstants.UPDATE_WEB_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: settingsConstants.UPDATE_WEB_SETTINGS_FAILURE, error } }
}
function getSmsSettings() {

    return dispatch => {
        dispatch(request());
        settingsService.getSmsSettings()
            .then(
                settings => dispatch(success(settings)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: settingsConstants.GETALL_SMS_SETTINGS_REQUEST } }
    function success(settings) { return { type: settingsConstants.GETALL_SMS_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: settingsConstants.GETALL_SMS_SETTINGS_FAILURE, error } }

}
function handleSmsChangeInput(getSmsMgmt,name,value) {
    return dispatch => {
                    let settings={...getSmsMgmt,[name]:value};
                    console.log("settings   ",settings);
                    dispatch(success(settings));
    };
    function success(settings) { return { type: settingsConstants.INPUTCHANGE_SMS_SETTINGS_SUCCESS, settings } }
}
function updateSmsSettings(data) {
  
    return dispatch => {
        dispatch(request());
        settingsService.updateSmsSettings(data)
            .then(
                settings => {
                    dispatch(success(settings));
                    dispatch(this.getSmsSettings());
                    dispatch(alertActions.error("Sms setting updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: settingsConstants.UPDATE_SMS_SETTINGS_REQUEST } }
    function success(settings) { return { type: settingsConstants.UPDATE_SMS_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: settingsConstants.UPDATE_SMS_SETTINGS_FAILURE, error } }
}