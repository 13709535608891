import React, { Component } from 'react';
import {
  Grid,
  //LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
} from "@material-ui/core";
//import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import GoogleMap from "../../components/GoogleMap";
import { Typography } from "../../components/Wrappers";
const LOS_ANGELES_CENTER = [34.0522, -118.2437];
const getInfoWindowString = place => `
      <div>
        <div style="font-size: 16px;">
          ${place.name}
        </div>
        <div style="font-size: 14px;">
          <span style="color: grey;">
          ${place.rating}
          </span>
          <span style="color: orange;">${String.fromCharCode(9733).repeat(Math.floor(place.rating))}</span><span style="color: lightgrey;">${String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}</span>
        </div>
        <div style="font-size: 14px; color: grey;">
          ${place.types[0]}
        </div>
        <div style="font-size: 14px; color: grey;">
          ${'$'.repeat(place.price_level)}
        </div>
        <div style="font-size: 14px; color: green;">
          ${place.opening_hours.open_now ? 'Open' : 'Closed'}
        </div>
      </div>
    `;

const handleApiLoaded = (map, maps, places) => {
  const markers = [];
  const infowindows = [];

  places.forEach((place) => {
    markers.push(new maps.Marker({
      position: {
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      },
      map,
    }));

    infowindows.push(new maps.InfoWindow({
      content: getInfoWindowString(place),
    }));
  });

  markers.forEach((marker, i) => {
    marker.addListener('click', () => {
      infowindows[i].open(map, marker);
    });
  });
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      markers: [],
      places: [],
    }
  }
  componentDidMount() {
   // this.props.dispatch(userActions.statics());
  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }

  render() {
    let classes = {};
    const { places } = this.state;
    let { users } = this.props;
    let { statics} = users;
    return (
      <>
        <PageTitle title="Dashboard"/>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total User"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {statics?statics.totalImage:300}
              </Typography>
              </div>
            </Widget>
          </Grid>
          
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total News"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                {statics?statics.totalNotification:350}
              </Typography>
              </div>
            </Widget>
          </Grid>
          
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total New Notification"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                {statics?statics.totalNotification:150}
              </Typography>
              </div>
            </Widget>
          </Grid>
        </Grid>
        <div style={{ height: '100vh', width: '100%' }}>
          <>
            {
              places && places.length > 1 && false ?
                <GoogleMap
                  defaultZoom={10}
                  defaultCenter={LOS_ANGELES_CENTER}
                  // scrollwheel= {false}
                  // zoomControl= {true}
                  // gestureHandling= 'cooperative'
                  bootstrapURLKeys={{ key: "AIzaSyC0Wg4XZU-975h_bvgR9p4b_kpPexiRddQ" }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, places)}
                /> : null
            }
          </>
        </div>
      </>
    );
  }
}
Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users
  };
}
export default connect(mapStateToProps)(Dashboard);
