
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const newsService = {
    login,
    logout,
    addNews,
    uploadImage,
    getNewsList,
    disableNews,
    updateNews,
    deleteNews,
    getAllNews,
};
function logout() {
    localStorage.removeItem('news');
}
function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
            .then(handleResponse)
            .then(news => {
                let newsObj = {
                    newsinfo: news.data
                }
                if (news.data) {
                    localStorage.setItem('news', JSON.stringify(news.data));
                }
                
                return newsObj;
            });
}
function getNewsList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNewsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let newsObj = {
                getNewsList: data.data
            }
            console.log("i am in service",newsObj);
            
            return newsObj;
        });
}
function getAllNews() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllNews`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let newsObj = {
                getAllNews: data.data
            }
            console.log();
            
            return newsObj;
        });
}
function addNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNews`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let newsObj = {
                addNews: data.data
            }
            console.log();
            
            return newsObj;
        });
}
function updateNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNews`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let newsObj = {
                addNews: data.data
            }
            return newsObj;
        });
}
function disableNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let newsObj = {
                addNews: data.data
            }
            console.log();
            
            return newsObj;
        });
}
function deleteNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteNews`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let newsObj = {
                addNews: data.data
            }
            console.log();
            
            return newsObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let newsObj = {
                filesDetails: res.data
            }
            console.log("i am in service",newsObj);
            return newsObj;
        });
}
function handleResponse(response) {
   // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            // console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {

            // console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}