import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };
const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function TableComponent(props) {
  let { data, offset, updatehandleOpenCreateModal, onDeleteClick, onDisable, classes,imageOpenCreateModal } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "700" }}>S. NO.</TableCell>
              <TableCell style={{ fontWeight: "700" }}>TITLE</TableCell>
              <TableCell style={{ fontWeight: "700" }}>SUBTITLE</TableCell>
              <TableCell style={{ fontWeight: "700" }}>CONTENT</TableCell>
              <TableCell style={{ fontWeight: "700" }}>CATEGORY</TableCell>
              <TableCell style={{ fontWeight: "700" }}>STORY_LINK</TableCell>
              <TableCell style={{ fontWeight: "700" }}>AUTHOR</TableCell>
              <TableCell style={{ fontWeight: "700" }}>ESTIMATES</TableCell>
              {/* <TableCell >RATING</TableCell> */}
              <TableCell style={{ fontWeight: "700" }}>IMAGE</TableCell>
              <TableCell style={{ fontWeight: "700" }}>TIME</TableCell>
              {/* <TableCell style={{textAlign:'center'}}>ACTION</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((tempdata, index) => (
                <TableRow key={index}>
                  <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
                  <TableCell style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.title}</TableCell>

                  <TableCell title={tempdata.subtitle} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.subtitle && tempdata.subtitle.length > 50 ? tempdata.subtitle.substring(0, 30) + "..." : tempdata.subtitle}</TableCell>

                  <TableCell title={tempdata.content} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.content && tempdata.content.length > 50 ? tempdata.content.substring(0, 30) + "..." : tempdata.content}</TableCell>

                  <TableCell style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.category}</TableCell>

                  <TableCell title={tempdata.storyLink} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.storyLink && tempdata.storyLink.length > 50 ? tempdata.storyLink.substring(0, 30) + "..." : tempdata.storyLink}</TableCell>

                  <TableCell title={tempdata.author } style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.author  && tempdata.author .length > 50 ? tempdata.author .substring(0, 30) + "..." : tempdata.author }</TableCell>

                  <TableCell style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.estimates}</TableCell>

                  <TableCell> <img src={tempdata.profileLink} onClick={() => imageOpenCreateModal(tempdata)} alt="tempdata.profileLink" width="100" height="100" style={{borderRadius:"50px"}}></img></TableCell>

                  <TableCell style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{tempdata.time}</TableCell>
                  {/* <TableCell>{tempdata.rating}</TableCell> */}

                  <TableCell style={{ textAlign: 'center' }}>
                    <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}  
                      onClick={() => updatehandleOpenCreateModal(tempdata)}>
                      Update
                </Button>

                    <Button
                      color={tempdata.isDisable ? "warning" : "primary"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px',marginTop: '5px', marginBottom:'5px'}}
                      onClick={() => onDisable(tempdata)}>
                      {tempdata.isDisable ? 'Disable' : 'Enable'}
                    </Button>

                    <Button
                      color="secondary"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDeleteClick(tempdata)}>
                      Delete
                </Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}



export default withStyles(styles)(TableComponent);