import {
  settingsConstants
} from '../_constants';

export function settings(state = {}, action) {

  switch (action.type) {
    case settingsConstants.GETALL_WEB_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GETALL_WEB_SETTINGS_SUCCESS:
      return {
        ...state,
        getWebMgmt: action.settings.getWebMgmt
      };
    case settingsConstants.GETALL_WEB_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case settingsConstants.UPDATE_WEB_SETTINGS_REQUEST:
      return {
        ...state
      };
    case settingsConstants.UPDATE_WEB_SETTINGS_SUCCESS:
      return {
        ...state,
        addSettingsSuccess: true
      };
    case settingsConstants.UPDATE_WEB_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case settingsConstants.INPUTCHANGE_WEB_SETTINGS_SUCCESS:
      return {
        ...state,
        getWebMgmt: action.settings
      };
    case settingsConstants.GETALL_SMS_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GETALL_SMS_SETTINGS_SUCCESS:
      return {
        ...state,
        getSmsMgmt: action.settings.getSmsMgmt
      };
    case settingsConstants.GETALL_SMS_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case settingsConstants.UPDATE_SMS_SETTINGS_REQUEST:
      return {
        ...state
      };
    case settingsConstants.UPDATE_SMS_SETTINGS_SUCCESS:
      return {
        ...state,
        addSettingsSuccess: true
      };
    case settingsConstants.UPDATE_SMS_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case settingsConstants.INPUTCHANGE_SMS_SETTINGS_SUCCESS:
      return {
        ...state,
        getSmsMgmt: action.settings
      };
    default:
      return state
  }
}