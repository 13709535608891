import {
  commentsConstants
} from '../_constants';

export function comments(state = {}, action) {
  console.log("action.comments  ",action.comments);
  switch (action.type) {


    case commentsConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.GETALL_SUCCESS:
      return {
        ...state,
        addCommentsSuccess: false,
        items: action.comments.getCommentsList.list,
        // total: action.comments.getCommentsList.total
      };
    case commentsConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case commentsConstants.GET_ALL_COMMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.GET_ALL_COMMENTS_SUCCESS:
      return {
        ...state,
        getAllComments: action.comments.getCommentsList
      };
    case commentsConstants.GET_ALL_COMMENTS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case commentsConstants.GETALL_COMMENTS_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.GETALL_COMMENTS_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.comments.listOfNotification.list,
        listOfNotificationtotal: action.comments.listOfNotification.total
      };
    case commentsConstants.GETALL_COMMENTS_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case commentsConstants.UPDATE_COMMENTS_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.UPDATE_COMMENTS_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case commentsConstants.UPDATE_COMMENTS_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case commentsConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.comments.restaurantDetails
      };
    case commentsConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case commentsConstants.ADD_RESTAURANT_COMMENTS_REQUEST:
      return {
        ...state
      };
    case commentsConstants.ADD_RESTAURANT_COMMENTS_SUCCESS:
      return {
        ...state,
        addCommentsSuccess: true
      };
    case commentsConstants.ADD_RESTAURANT_COMMENTS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case commentsConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case commentsConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case commentsConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case commentsConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case commentsConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case commentsConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case commentsConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case commentsConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case commentsConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case commentsConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case commentsConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case commentsConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case commentsConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case commentsConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case commentsConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case commentsConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.comments.statics
      };
    case commentsConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}