import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function UpdateNewsDialog(props) {

  let { classes, updateformData, updatenewsCreateModal, updatehandleClose, updatehandleSubmit, updatehandleChangeInput, handleFileLogo, handleUploadLogo } = props;


  // let { classes, updateformData, updatenewsCreateModal, updatehandleClose, updateupdatehandleChangeInput, filesDetails, updatehandleSubmit, updatehandleChangeInput, updateformData,filesDetails, handleFileLogo, handleUploadLogo } = props;

  return (
    <Dialog fullScreen open={updatenewsCreateModal} onClose={updatehandleClose} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={updatehandleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update News
      </Typography>
          <Button color="inherit" onClick={updatehandleClose}>
            Cancel
      </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update News
        </Typography>

          <ValidatorForm
            onSubmit={updatehandleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="title*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="title"
                  id="title"
                  value={updateformData.title}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>


            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="subtitle*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="subtitle"
                  id="subtitle"
                  value={updateformData.subtitle}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>


            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="content*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="content"
                  id="content"
                  value={updateformData.content}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="category*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="category"
                  id="category"
                  value={updateformData.category}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="storyLink*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="storyLink"
                  id="storyLink"
                  value={updateformData.storyLink}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="author*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="author"
                  id="author"
                  value={updateformData.author}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="estimates*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="estimates"
                  id="estimates"
                  value={updateformData.estimates}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="image*"
                  fullWidth
                  disabled={true}
                  // onChange={updatehandleChangeInput}
                  name="image"
                  id="image"
                  value={updateformData.image}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <label htmlFor="logo">
                <Button color="default" variant="contained" component="span">
                  Browse
                </Button>
              </label>
              <input
                style={{ display: "none" }}
                id="logo"
                name="logo"
                type="file"
                onChange={handleFileLogo}
              />

              <Button
                style={{ marginLeft: "20px" }}
                color="primary"
                variant="contained"
                component="span"
                onClick={() => handleUploadLogo()}
              >
                Upload
                </Button>
              {/* <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => this.handleLogoRemove()}>
                  Remove
                  </Button> */}
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="time*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="time"
                  id="time"
                  value={updateformData.time}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
          </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog >
  );
}
