import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { settingsActions } from '../../_actions';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import styles from './styles';


class SettingsManagement extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formData: {
        "name": "",
        "details": "",
        "months": "",
        "rate": ""
      },
    }
  }
  componentDidMount() {
    this.props.dispatch(settingsActions.getWebSettings());
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  }
  handleChangeInput = (event) => {
    let {name,value}=event.target;
    let { settings } = this.props;
    let { getWebMgmt } = settings;

    this.props.dispatch(settingsActions.handleChangeInput(getWebMgmt,name,value));
    // const { formData } = this.state;
    // formData[event.target.name] = event.target.value;
    // this.setState({ formData });
  }
  handleSubmit = () => {

    let { settings } = this.props;
    let { getWebMgmt } = settings;

    this.props.dispatch(settingsActions.updateWebSettings(getWebMgmt));
  }
  render() {

    let { settings, classes } = this.props;
    let { getWebMgmt } = settings;
    console.log(getWebMgmt);

    return (
      <>
        <PageTitle title="Settings" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              {getWebMgmt?<div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Update Web Settings
                </Typography>

                <ValidatorForm
                  onSubmit={this.handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}>

                      <TextValidator
                        label="title*"
                        fullWidth
                        onChange={this.handleChangeInput}
                        name="title"
                        id="title"
                        value={getWebMgmt.title}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <TextValidator
                        label="shortTitle*"
                        id="shortTitle"
                        fullWidth
                        onChange={this.handleChangeInput}
                        name="shortTitle"
                        value={getWebMgmt.shortTitle}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <TextValidator
                        label="footer*"
                        id="footer"
                        fullWidth
                        onChange={this.handleChangeInput}
                        name="footer"
                        value={getWebMgmt.footer}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.submit}
                  >
                    Save
          </Button>
                </ValidatorForm>
              </div>:null}



            </Widget>
          </Grid>
        </Grid>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { settings } = state;
  return {
    settings
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(SettingsManagement)));
