import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";
import { Fullscreen } from "@material-ui/icons";



export default function ViewImageDialog(props) {

  let { classes, viewformData, updatenewsCreateModal, viewhandleClose, } = props;

  console.log("viewformData  ", viewformData);
  // let { classes, viewformData, updatenewsCreateModal, viewhandleClose, updateupdatehandleChangeInput, filesDetails, updatehandleSubmit, updatehandleChangeInput, viewformData,filesDetails, handleFileLogo, handleUploadLogo } = props;

  return (
    <Dialog open={updatenewsCreateModal} onClose={viewhandleClose} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={viewhandleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Image
      </Typography>
          <Button color="inherit" onClick={viewhandleClose}>
            Cancel
      </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
          <ypography style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{viewformData.title}</ypography>
        </Typography>         
            <img src={viewformData.profileLink} height={400} width={400} style={{paddingBottom:"20px"}}></img>
        </div>
      </Container>
    </Dialog >
  );
}
