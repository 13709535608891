export const settingsConstants = {

    GETALL_WEB_SETTINGS_REQUEST: 'GETALL_WEB_SETTINGS_REQUEST',
    GETALL_WEB_SETTINGS_SUCCESS: 'GETALL_WEB_SETTINGS_SUCCESS',
    GETALL_WEB_SETTINGS_FAILURE: 'GETALL_WEB_SETTINGS_FAILURE',

    UPDATE_WEB_SETTINGS_REQUEST: 'UPDATE_WEB_SETTINGS_REQUEST',
    UPDATE_WEB_SETTINGS_SUCCESS: 'UPDATE_WEB_SETTINGS_SUCCESS',
    UPDATE_WEB_SETTINGS_FAILURE: 'UPDATE_WEB_SETTINGS_FAILURE',

    INPUTCHANGE_WEB_SETTINGS_SUCCESS: 'INPUTCHANGE_WEB_SETTINGS_SUCCESS',


    GETALL_SMS_SETTINGS_REQUEST: 'GETALL_SMS_SETTINGS_REQUEST',
    GETALL_SMS_SETTINGS_SUCCESS: 'GETALL_SMS_SETTINGS_SUCCESS',
    GETALL_SMS_SETTINGS_FAILURE: 'GETALL_SMS_SETTINGS_FAILURE',

    UPDATE_SMS_SETTINGS_REQUEST: 'UPDATE_SMS_SETTINGS_REQUEST',
    UPDATE_SMS_SETTINGS_SUCCESS: 'UPDATE_SMS_SETTINGS_SUCCESS',
    UPDATE_SMS_SETTINGS_FAILURE: 'UPDATE_SMS_SETTINGS_FAILURE',

    INPUTCHANGE_SMS_SETTINGS_SUCCESS: 'INPUTCHANGE_SMS_SETTINGS_SUCCESS',


};