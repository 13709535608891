import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function AddCommentsDialog(props) {

  let {classes,formData,commentsCreateModal,handleClose,handleChangeInput,handleSubmit}=props;
  //console.log("commentsCreateModal  ",commentsCreateModal);
  
  return (
    <Dialog fullScreen open={commentsCreateModal} onClose={handleClose} >
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Add New Comments
      </Typography>
        <Button color="inherit" onClick={handleClose}>
          Cancel
      </Button>
      </Toolbar>
    </AppBar>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Add Comments
        </Typography>
       
        <ValidatorForm
          
          onSubmit={handleSubmit}
      >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
             
              <TextValidator
                label="firstName*"
                fullWidth
                onChange={handleChangeInput}
                name="firstName"
                id="firstName"
                value={formData.firstName}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="lastName*"
                id="lastName"
                fullWidth
                onChange={handleChangeInput}
                name="lastName"
                value={formData.lastName}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="email*"
                id="email"
                fullWidth
                onChange={handleChangeInput}
                name="email"
                value={formData.email}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="password*"
                id="password"
                fullWidth
                onChange={handleChangeInput}
                name="password"
                value={formData.password}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                label="gender*"
                id="gender"
                fullWidth
                onChange={handleChangeInput}
                name="gender"
                value={formData.gender}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="dob*"
                id="dob"
                fullWidth
                onChange={handleChangeInput}
                name="dob"
                value={formData.dob}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="doj*"
                id="doj"
                fullWidth
                onChange={handleChangeInput}
                name="doj"
                value={formData.doj}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="mobile*"
                id="mobile"
                fullWidth
                onChange={handleChangeInput}
                name="mobile"
                value={formData.mobile}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="address*"
                id="address"
                fullWidth
                onChange={handleChangeInput}
                name="address"
                value={formData.address}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="height*"
                id="height"
                fullWidth
                onChange={handleChangeInput}
                name="height"
                value={formData.height}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="weight*"
                id="weight"
                fullWidth
                onChange={handleChangeInput}
                name="weight"
                value={formData.weight}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
              type="submit"
            className={classes.submit}
          >
            Save
          </Button>
        </ValidatorForm>
      </div>
    </Container>
  </Dialog>
  );
}
