import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";



import NewsManagement from "../../pages/NewsManagement"
import Comments from "../../pages/CommentsManagement"
import Dashboard from "../../pages/dashboard";
import Notifications from "../../pages/notifications";
import WebManagement from "../../pages/WebManagement";
// import SmsManagement from "../../pages/SmsManagement";
import UserManagement from "../../pages/UserManagement";
import { useLayoutState } from "../../context/LayoutContext";
import { store } from '../../_helpers';

function Layout(props) {
  let users=store.getState().users;
  console.log("users :: ",users);
  
  var classes = useStyles();
  var layoutState = useLayoutState();
  return (

      <div className={classes.root}>
        <>

          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />

            <Switch>

            <Route path="/app/News" component={withRouter(NewsManagement)} />
            <Route path="/app/Comments" component={withRouter(Comments)} />
            <Route path="/app/dashboard" component={withRouter(Dashboard)} />
            <Route path="/app/web" exact component={withRouter(WebManagement)} />
            {/* <Route path="/app/sms" exact component={withRouter(SmsManagement)} /> */}
            <Route path="/app/user" exact component={withRouter(UserManagement)} />
            <Route path="/app/notifications" component={withRouter(Notifications)} />
            
            </Switch>

          </div>

        </>
      </div>
  );
}
export default withRouter(Layout);
