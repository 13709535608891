
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const commentsService = {
    login,
    logout,
    addComments,
    uploadImage,
    getCommentsList,
    disableComments,
    updateComments,
    deleteComments,
    getAllComments,
};
function logout() {
    localStorage.removeItem('comments');
}
function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
            .then(handleResponse)
            .then(comments => {
                let commentsObj = {
                    commentsinfo: comments.data
                }
                if (comments.data) {
                    localStorage.setItem('comments', JSON.stringify(comments.data));
                }
                
                return commentsObj;
            });
}
function getCommentsList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch('https://jsonplaceholder.typicode.com/comments', requestOptions)
        .then(handleResponse)
        .then(data => {
            let commentsObj = {
                getCommentsList: data
            }
            // console.log("i am in serviceserviceserviceservice",commentsObj);
            
            return commentsObj;
        });
}
function getAllComments() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(`https://jsonplaceholder.typicode.com/comments`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let commentsObj = {
                getAllComments: data.data
            }
            console.log();
            
            return commentsObj;
        });
}
function addComments(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let commentsObj = {
                addComments: data.data
            }
            console.log();
            
            return commentsObj;
        });
}
function updateComments(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let commentsObj = {
                addComments: data.data
            }
            return commentsObj;
        });
}
function disableComments(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let commentsObj = {
                addComments: data.data
            }
            console.log();
            
            return commentsObj;
        });
}
function deleteComments(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let commentsObj = {
                addComments: data.data
            }
            console.log();
            
            return commentsObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let commentsObj = {
                filesDetails: res.data
            }
            return commentsObj;
        });
}
function handleResponse(response) {
   // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}