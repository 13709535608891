import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { dashboard } from './dashboard.reducer';
import { settings } from './settings.reducer';
import { comments } from './comments.reducer';
import { news } from './news.reducer';
import { users } from './users.reducer';

const rootReducer = combineReducers({
  //branch,
  authentication,
  alert,
  dashboard,
  settings,
  comments,
  news,
  users,
});

export default rootReducer;
