export const newsConstants = {
    LOGIN_REQUEST: 'NEWS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'NEWS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'NEWS_LOGIN_FAILURE',

    LOGOUT: 'NEWS_LOGOUT',

    GETALL_NEWS_REQUEST: 'GETALL_NEWS_REQUEST',
    GETALL_NEWS_SUCCESS: 'GETALL_NEWS_SUCCESS',
    GETALL_NEWS_FAILURE: 'GETALL_NEWS_FAILURE',  

    GET_ALL_NEWS_REQUEST: 'NEWS_GET_ALL_NEWS_REQUEST',
    GET_ALL_NEWS_SUCCESS: 'NEWS_GET_ALL_NEWS_SUCCESS',
    GET_ALL_NEWS_FAILURE: 'NEWS_GET_ALL_NEWS_FAILURE',
    
    GETALL_NEWS_NOTIFY_REQUEST: 'GETALL_NEWS_NOTIFY_REQUEST',
    GETALL_NEWS_NOTIFY_SUCCESS: 'GETALL_NEWS_NOTIFY_SUCCESS',
    GETALL_NEWS_NOTIFY_FAILURE: 'GETALL_NEWS_NOTIFY_FAILURE',

    UPDATE_NEWS_NOTIFY_REQUEST: 'UPDATE_NEWS_NOTIFY_REQUEST',
    UPDATE_NEWS_NOTIFY_SUCCESS: 'UPDATE_NEWS_NOTIFY_SUCCESS',
    UPDATE_NEWS_NOTIFY_FAILURE: 'UPDATE_NEWS_NOTIFY_FAILURE',


    ADD_RESTAURANT_NEWS_REQUEST: 'ADD_RESTAURANT_NEWS_REQUEST',
    ADD_RESTAURANT_NEWS_SUCCESS: 'ADD_RESTAURANT_NEWS_SUCCESS',
    ADD_RESTAURANT_NEWS_FAILURE: 'ADD_RESTAURANT_NEWS_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

    FILE_UPLOAD_BLANK_SUCCESS: 'FILE_UPLOAD_BLANK_SUCCESS'

};
