import { commentsConstants } from '../_constants';
import { commentsService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
export const commentsActions = {
    login,
    logout,
    addComments,
    uploadImage,
    disableComments,
    deleteComments,
    updateComments,
    getAllComments,
    getCommentsList
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        commentsService.login(data)
            .then(
                comments => {
                    dispatch(success(comments));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(comments) { return { type: commentsConstants.LOGIN_REQUEST, comments } }
    function success(comments) { return { type: commentsConstants.LOGIN_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.LOGIN_FAILURE, error } }
}
function logout() {
    commentsService.logout();
    return { type: commentsConstants.LOGOUT };
}
function getCommentsList(data) {
    return dispatch => {
        dispatch(request());
        commentsService.getCommentsList(data)
            .then(
                comments => dispatch(success(comments)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.GET_ALL_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.GET_ALL_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.GET_ALL_COMMENTS_FAILURE, error } }
}
function getAllComments(data) {
    return dispatch => {
        dispatch(request());
        commentsService.getAllComments(data)
            .then(
                comments => {
                    //console.log("comments  ",comments);
                    let returnResppnse=
                    comments.getAllComments?comments.getAllComments.map(comments => ({ value: comments.id, label: comments.firstName+" "+comments.lastName })):[];
                    let newRespnse={...comments,getAllComments:returnResppnse}
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.GET_ALL_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.GET_ALL_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.GET_ALL_COMMENTS_FAILURE, error } }
}
function addComments(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        commentsService.addComments(data)
            .then(
                comments => {
                    dispatch(success(comments));
                    dispatch(this.getCommentsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_FAILURE, error } }
}
function updateComments(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        commentsService.updateComments(data)
            .then(
                comments => {
                    dispatch(success(comments));
                    dispatch(this.getCommentsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_FAILURE, error } }
}
function disableComments(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        commentsService.disableComments(maintempdata)
            .then(
                comments => {
                    dispatch(success(comments));
                    dispatch(this.getCommentsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_FAILURE, error } }
}
function deleteComments(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        commentsService.deleteComments(maintempdata)
            .then(
                comments => {
                    dispatch(success(comments));
                    dispatch(this.getCommentsList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_REQUEST } }
    function success(comments) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_SUCCESS, comments } }
    function failure(error) { return { type: commentsConstants.ADD_RESTAURANT_COMMENTS_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        commentsService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: commentsConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: commentsConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
