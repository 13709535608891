
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const settingsService = {
    logout,
    getWebSettings,
    updateWebSettings,
    getSmsSettings,
    updateSmsSettings,
};
function logout() {
    localStorage.removeItem('settings');
}
function getWebSettings() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getWebMgmt`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let settingsObj = {
                getWebMgmt: data.data
            }
            console.log();
            
            return settingsObj;
        });
}
function updateWebSettings(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    delete data['createdAt'];
    delete data['id'];
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateWebMgmt`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let settingsObj = {
                addSettings: data.data
            }
            return settingsObj;
        });
}
function getSmsSettings() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getSmsMgmt`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let settingsObj = {
                getSmsMgmt: data.data
            }
            console.log();
            
            return settingsObj;
        });
}
function updateSmsSettings(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    delete data['createdAt'];
    delete data['id'];
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSmsMgmt`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let settingsObj = {
                addSettings: data.data
            }
            return settingsObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}