import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };

export default function TableComponent(props) {
  let {data,offset,updatehandleOpenCreateModal,onDeleteClick,onDisable}=props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >FIRST NAME</TableCell>
          <TableCell >LAST NAME</TableCell>
          <TableCell >DOJ</TableCell>
          {/* <TableCell >RATING</TableCell> */}
          <TableCell >MOBILE</TableCell>
          <TableCell style={{textAlign:'center'}}>ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.email}</TableCell>
              <TableCell>{tempdata.body}</TableCell>
              <TableCell>{tempdata.name}</TableCell>
              <TableCell>{tempdata.postId}</TableCell>
              {/* <TableCell>{tempdata.rating}</TableCell> */}
              <TableCell style={{textAlign:'center'}}>
                <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{marginRight:'5px'}}
                  onClick={()=>updatehandleOpenCreateModal(tempdata)}
                >
                More Details..
              </Button>

              <Button
                  color={tempdata.isDisable?"warning":"primary"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{marginRight:'5px'}}
                  onClick={()=>onDisable(tempdata)}
                >
                {tempdata.isDisable?'Disable':'Enable'}
              </Button>
              <Button
                  color="secondary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{marginRight:'5px'}}
                  onClick={()=>onDeleteClick(tempdata)}
                >
                Delete
              </Button>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
